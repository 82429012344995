import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Modal from 'react-modal';

import WeddingPage from './components/WeddingPage';
import HeaderMeta from './components/HeaderMeta';

// Gắn modal vào root của ứng dụng (chỉ gọi 1 lần ở file gốc)
Modal.setAppElement('#root');

function App() {
  return (
    <Router>
      <HeaderMeta />
      <Routes>
        <Route path="/:slug" element={<WeddingPage />} />
        <Route path="/" element={<WeddingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
