import React from 'react';
import { motion } from 'framer-motion';
import '../App.css';

import topleft from '../assets/images/top-left.png';
import topright from '../assets/images/top-right.png';
import bottomleft from '../assets/images/bottom-left.png';
import bottomright from '../assets/images/bottom-right.png';

import leaf1 from '../assets/images/leaf-1.png';
import leaf3 from '../assets/images/leaf-3.png';
import Guest from './Guest';
import DateInvitation from './DateInvitation';
import Location from './Location';
import FloatingPetals from './FloatingPetals';
import RSVPPopup from './RSVPPopup';

import nhaTrai from '../data/nhaTrai.json';
import nhaGai from '../data/nhaGai.json';

const WeddingInvitation = ({ guest }) => {
  const data = guest.guestType === 'nhagai' ? nhaGai : nhaTrai;

  return (
    <section className="bg-custom relative h-screen flex items-center justify-center text-gray-800 overflow-hidden">
      <motion.img
        src={topleft}
        alt="top-left"
        className="absolute top-0 left-0 w-1/3 max-w-xs z-10"
        animate={{ y: [0, 8, 0] }}
        transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
      />

      <motion.img
        src={leaf1}
        alt="leaf 1"
        className="absolute bottom-[25%] left-[-2%] w-1/6 max-w-xs z-10"
        animate={{ y: [0, -5, 0] }}
        transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
      />

      <motion.img
        src={leaf3}
        alt="leaf 1"
        className="absolute bottom-0 left-[15%] w-1/4 max-w-xs z-10"
        animate={{ y: [0, -10, 0] }}
        transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
      />

      <motion.img
        src={topright}
        alt="top-right"
        className="absolute top-0 right-0 w-1/4 max-w-xs z-10"
        animate={{ y: [0, -8, 0] }}
        transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
      />

      <motion.img
        src={bottomleft}
        alt="bottom-left"
        className="absolute bottom-0 left-0 w-1/4 max-w-xs z-10"
        animate={{ y: [0, -10, 0] }}
        transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
      />

      <motion.img
        src={bottomright}
        alt="bottom-right"
        className="absolute bottom-0 right-0 w-1/3 max-w-sm z-10"
        animate={{ y: [0, 10, 0] }}
        transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
      />

      <div className="text-center z-20 animate-fadeIn px-4">
        <Guest guestName={guest.name} />
        <h2 className="text-[50px] sm:text-[180px] font-allura text-primary mb-4 leading-none ">
          Save the Date
        </h2>
        <h1 className="text-[30px] sm:text-[40px] text-primary name-wedding mb-4">
          {data.name}
        </h1>

        <DateInvitation data={data} />
        <Location data={data} />

        <p className="text-[30px] sm:text-[40px] font-allura mb-10 lg:mb-10 leading-snug text-primary">
          Your presence is our greatest gift!
        </p>

        <RSVPPopup data={data} />
      </div>

      <FloatingPetals />
    </section>
  );
};

export default WeddingInvitation;
