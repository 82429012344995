import React from 'react';

import '../App.css';

const DateInvitation = ({ data }) => (
  <div className="text-lg flex relative max-w my-10 lg:my-20 text-primary">
    <div className="w-1/3 text-[20px] lg:text-[30px] font-baskerville">
      {data.dateTime.time}
    </div>
    <div className="w-1/3 text-[20px] lg:text-[30px] font-baskerville font-thin">
      |
    </div>
    <div className="w-1/3 text-[20px] lg:text-[30px] font-baskerville">
      {' '}
      {data.dateTime.date}
    </div>
  </div>
);

export default DateInvitation;
