import React from 'react';

import '../App.css';

const Guest = ({ guestName }) => (
  <section className="font-baskerville py-5 lg:py-12 text-center text-uppercase text-primary tracking-wide font-light ">
    {guestName && (
      <div className="text-center mt-10 leading-relaxed my-10 lg:my-20">
        {/* <p className="text-xl font-allura text-gray-700 mb-1">
          Chúng tôi trân trọng kính mời
        </p> */}
        <p className="text-base italic text-[27px] mb-3">
          We cordially invite you to join our wedding
        </p>

        <p
          className="text-xl font-semibold text-[30px]"
          style={{ color: '#b87333' }}
        >
          {guestName}
        </p>
      </div>
    )}
  </section>
);

export default Guest;
