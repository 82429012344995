import React from 'react';
import { Helmet } from 'react-helmet';

import thumbnail from '../assets/images/thumbnail.jpg';

const HeaderMeta = () => {
  return (
    <Helmet>
      <title>Đăng & Hồng's Wedding invitation</title>
      <meta
        name="description"
        content="Đăng & Hồng's Wedding invitation - Your presence is our greatest gift!"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content="Đăng & Hồng - Wedding invitation." />
      <meta
        property="og:description"
        content="We cordially invite you to join our wedding - Your presence is our greatest gift!"
      />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:type" content="website" />
      <meta charSet="utf-8" />
    </Helmet>
  );
};

export default HeaderMeta;
