import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WeddingInvitation from './WeddingInvitation';
import guestList from '../data/guests.json';

const WeddingPage = () => {
  const { slug } = useParams();
  const [guest, setGuest] = useState({});

  useEffect(() => {
    const guest = guestList.find((g) => g.slug === slug);

    setGuest(guest || {});
  }, [slug]);

  return <WeddingInvitation guest={guest} />;
};

export default WeddingPage;
