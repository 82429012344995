import React from 'react';

import '../App.css';

const Location = ({ data }) => (
  <div className="tflex relative max-w my-10 lg:my-20 text-primary">
    <a
      href={data.calendarLink}
      target="_blank"
      rel="noopener noreferrer"
      className="transition underline"
    >
      <p className="text-center sm:text-[20px] font-baskerville text-uppercase">
        {data.location.name}
      </p>
      <p className="text-center sm:text-[20px] font-baskerville text-uppercase">
        {data.location.value}
      </p>
    </a>
  </div>
);

export default Location;
