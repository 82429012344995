import React from 'react';
import { motion } from 'framer-motion';

import flower1 from '../assets/images/flower-gold-1.png';
import flower2 from '../assets/images/flower-gold-2.png';
import flower3 from '../assets/images/flower-gold-3.png';

const FloatingPetals = () => {
  const delay = Math.random() * 5;
  const duration = 8 + Math.random() * 4;
  const size = 300 + Math.random() * 20;

  return (
    <>
      <motion.img
        src={flower1}
        alt="petal"
        className="pointer-events-none absolute z-10"
        style={{
          width: `${size}px`,
          height: 'auto',
          left: `7vw`,
          top: `-${Math.random() * 100}px`,
          opacity: 0.8,
        }}
        initial={{ y: -100, rotate: 0, opacity: 0 }}
        animate={{
          y: '100vh',
          opacity: [0, 1, 0.8, 0.5, 0],
        }}
        transition={{
          duration,
          delay,
          repeat: Infinity,
          ease: 'linear',
        }}
      />

      <motion.img
        src={flower3}
        alt="petal"
        className="pointer-events-none absolute z-10"
        style={{
          width: `${size}px`,
          height: 'auto',
          left: `50vw`,
          top: `-${Math.random() * 100}px`,
          opacity: 0.8,
        }}
        initial={{ y: -100, rotate: 0, opacity: 0 }}
        animate={{
          y: '50vh',
          opacity: [0, 1, 0.8, 0.5, 0],
        }}
        transition={{
          duration,
          delay,
          repeat: Infinity,
          ease: 'linear',
        }}
      />

      <motion.img
        src={flower2}
        alt="petal"
        className="pointer-events-none absolute z-10"
        style={{
          width: `${size}px`,
          height: 'auto',
          right: `10vw`,
          top: `-${Math.random() * 100}px`,
          opacity: 0.8,
        }}
        initial={{ y: -100, rotate: 0, opacity: 0 }}
        animate={{
          y: '50vh',
          opacity: [0, 1, 0.8, 0.5, 0],
        }}
        transition={{
          duration,
          delay,
          repeat: Infinity,
          ease: 'linear',
        }}
      />
    </>
  );
};

export default FloatingPetals;
