import React, { useState } from 'react';
import Modal from 'react-modal';

const RSVPPopup = ({ data }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className="text-center mb-10 lg:mb-20">
      <button
        onClick={() => setModalIsOpen(true)}
        className="px-6 py-2 bg-transparent border border-primary text-primary font-medium tracking-wide uppercase rounded-full hover:bg-primary hover:text-white transition duration-300"
      >
        💌 RSVP Now
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="RSVP Modal"
        className="bg-white p-6 rounded-lg max-w-lg mx-auto shadow-lg outline-none w-[100%] h-[70%]"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
      >
        <button
          onClick={() => setModalIsOpen(false)}
          className="absolute top-2 right-3 text-2xl text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>

        <h2 className="text-xl font-bold text-primary mb-4">RSVP</h2>
        <iframe
          src={data.rsvpLink}
          width="100%"
          height="95%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </Modal>
    </div>
  );
};

export default RSVPPopup;
